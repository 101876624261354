.error-message {
  background-color: #ffcccc;
  border: 1px solid #ff0000;
  color: #ff0000;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.error-icon {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
}

.error-text {
  font-size: 16px;
}
