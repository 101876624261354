* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}


.login-wrap {
  position: relative;
  margin: 0 auto;
  background: #ecf0f1;
  border-radius: 5px;
  box-shadow: 3px 3px 10px #333;
  padding: 15px;
  display: flex;
}

.login-wrap:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to right, #27ae60 0%, #27ae60 20%, #8e44ad 20%, #8e44ad 40%, #3498db 40%, #3498db 60%, #e74c3c 60%, #e74c3c 80%, #f1c40f 80%, #f1c40f 100%);
  height: 5px;
  border-radius: 5px 5px 0 0;
}

.chapter-text-container {
  flex: 1;
}

.chapter-image-container {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 2px solid #080d25;
  width: 40%;
  height: 40%;
  max-width: 300px;
  max-height: 300px;
}

.chapter-image {
  max-width: 100%;
  max-height: 100%;
}


html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.result-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.story-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: 80%;
}

.arrow-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 6px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;
}

.text-content {
  flex-grow: 1;
  text-align: left;
}

.text-content .highlighted-word {
  background-color: yellow;
}

.speech-button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.speech-button {
  background-color: #3498db;
  color: #fff;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  cursor: pointer;
}

.radio-container {
  display: flex;
  justify-content: space-between;
    gap: 10px; /* Add the gap between the boxes */
}

.radio-box {
  width: 150px;
  height: 100%;
  border: 1px solid black;
  background-color: #ecf0f1;
  padding: 10px;
  transition: background-color 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.radio-box:hover {
  background-color: blue;
  color: white;
}

.radio-text {
  font-size: 14px;
  color: inherit;
  transition: color 0.3s ease;
}

.radio-box:hover .radio-text {
  color: white;
}

.radio-button-label {
  text-align: center;
}

button {
  display: inline-block;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: inherit;
  font-weight: bold;
  cursor: pointer;
  transition: color 0.3s ease;
  border: 1px solid black;
}

.radio-box:hover button {
  color: white;
}

.speech-button {
  color: black;
  background-color: transparent;
  font-size: 20px;
  margin-left: 300px;
  display: flex;
}
