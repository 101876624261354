.email-not-verified {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  padding: 55px;
  border-radius: 4px;
  color: #721c24;
  text-align: center;
  margin: 150px auto; /* Center the component horizontally and add margin around it */
  max-width: 400px; /* Limit the width to make it more compact on larger screens */
}

.email-not-verified h2 {
  margin-top: 0;
}

.email-not-verified p {
  margin-bottom: 0;
}
