@import url("https://fonts.googleapis.com/css?family=Dancing+Script|Roboto");

*,
*:after,
*:before {
  box-sizing: border-box;
}

body {
  background: #ff4081 / 1.25;
  text-align: center;
  font-family: "Roboto", arial;
}

.panda {
  position: relative;
  width: 200px;
  margin: 50px auto;
}

.face {
  width: 200px;
  height: 200px;
  background: #fff;
  border-radius: 100%;
  margin: 50px auto;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  z-index: 50;
  position: relative;
}

.ear {
  position: absolute;
  width: 80px;
  height: 80px;
  background: #000;
  z-index: 5;
  border: 10px solid #fff;
  left: -15px;
  top: -15px;
  border-radius: 100%;
}

.ear:after {
  content: '';
  position: absolute;
  width: 80px;
  height: 80px;
  background: #000;
  z-index: 5;
  border: 10px solid #fff;
  left: 125px;
  top: -15px;
  border-radius: 100%;
}

.eye-shade {
  background: #000;
  width: 50px;
  height: 80px;
  margin: 10px;
  position: absolute;
  top: 35px;
  left: 25px;
  transform: rotate(220deg);
  border-radius: 25px / 20px 30px 35px 40px;
}

.eye-shade.rgt {
  transform: rotate(140deg);
  left: 105px;
}

.eye-white {
  position: absolute;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background: #fff;
  z-index: 500;
  left: 40px;
  top: 80px;
  overflow: hidden;
}

.eye-white.rgt {
  right: 40px;
  left: auto;
}

.eye-ball {
  position: absolute;
  width: 0px;
  height: 0px;
  left: 20px;
  top: 20px;
  max-width: 10px;
  max-height: 10px;
  transition: 0.1s;
}

.eye-ball:after {
  content: "";
  background: #000;
  position: absolute;
  border-radius: 100%;
  right: 0;
  bottom: 0px;
  width: 20px;
  height: 20px;
}

.nose {
  position: absolute;
  height: 20px;
  width: 35px;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 50px 20px / 30px 15px;
  transform: rotate(15deg);
  background: #000;
}

.body {
  background: #fff;
  position: absolute;
  top: 200px;
  left: -20px;
  border-radius: 100px 100px 100px 100px / 126px 126px 96px 96px;
  width: 250px;
  height: 282px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
}

.hand {
  width: 50px;
  height: 40px;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  background: #000;
  margin: 5px;
  position: absolute;
  top: 70px;
  left: -25px;
}

.hand:after,
.hand:before {
  content: '';
  width: 40px;
  height: 30px;
  border-radius: 50px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.15);
  left: -5px;
  top: 11px;
}

.hand:before {
  top: 26px;
}

.hand.rgt {
  left: auto;
  right: -25px;
}

.hand.rgt:after,
.hand.rgt:before {
  left: auto;
  right: -5px;
}

.foot {
  top: 570px;
  left: -80px;
  position: absolute;
  background: #000;
  z-index: 1400;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  border-radius: 40px 40px 39px 40px / 26px 26px 63px 63px;
  width: 82px;
  height: 120px;
}

.foot:after {
  content: '';
  width: 55px;
  height: 65px;
  background: #222;
  border-radius: 100%;
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  margin: auto;
}

.finger {
  position: absolute;
  width: 25px;
  height: 35px;
  background: #222;
  border-radius: 100%;
  top: 10px;
  right: 5px;
}

.finger:after,
.finger:before {
  content: '';
  position: absolute;
  width: 20px;
  height: 35px;
  background: #222;
  border-radius: 100%;
  right: 30px;
  top: 0;
}

.finger:before {
  right: 55px;
  top: 5px;
}

.rgt {
  left: auto;
  right: -80px;
}

.rgt .finger {
  left: 5px;
  right: auto;
}

.rgt .finger:after {
  left: 30px;
  right: auto;
}

.rgt .finger:before {
  left: 55px;
  right: auto;
}

form {
  display: none;
  max-width: 400px;
  padding: 40px 40px;
  background: #02798b;
  height: 480px;
  margin: auto;
  display: block;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
  transition: 0.3s;
  position: relative;
  transform: translateY(-100px);
  z-index: 500;
  border: 1px solid #eee;
}

form.up {
  transform: translateY(-180px);
}

h1 {
  color: $primary;
  font-family: 'roboto', Arial;
}

.btn {
  background: #fff;
  padding: 5px;
  width: 150px;
  height: 35px;
  border: 1px solid $primary;
  margin-top: 25px;
  cursor: pointer;
  transition: 0.3s;
  box-shadow: 0 50px $primary inset;
  color: #fff;
}

.btn:hover {
  box-shadow: 0 0 $primary inset;
  color: $primary;
}

.btn:focus {
  outline: none;
}


form .form-group {
  position: relative;
  margin-bottom: 15px;
}

form .form-group .form-control {
  width: 100%;
  height: 50px;
  border: none;
  padding: 5px 7px 5px 15px;
  background: #f0f0f0;
  color: #666;
  font-size: 18px;
  border-radius: 25px;
}

form .form-group .form-label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px 7px 5px 15px;
  font-size: 18px;
  color: #999;
  cursor: text;
  transition: 0.25s ease;
  border-radius: 25px;
}

form .form-control:focus ~ .form-label,
form .form-control:valid ~ .form-label {
  top: -25px;
  left: -5px;
  background: #ff4081;
  color: #fff;
  font-size: 14px;
  padding: 0 5px 0 5px;
}

form select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAUCAYAAACEYr9/AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABZ0RVh0Q3JlYXRpb24gVGltZQAyMDIzLTA2LTI5VDE0OjQ1OjU1KzAyOjAwGhnc1AAAAJXRFWHRkYXRlOmNyZWF0ZQAyMDIzLTA2LTI5VDE0OjQ1OjU1KzAyOjAwfL8VRAAAABJ0RVh0UmVjb3JkIEZpbGUAMjAyMy0wNi0yOVQxNDoyNzoxOCswMjowMMpF2ZQAAAAASUVORK5CYII=);
  background-repeat: no-repeat;
  background-position: 95% center;
  padding-right: 40px;
}

form .btn {
  display: block;
  width: 100%;
  height: 5 0px;
  border: none;
  background: #7340ff;
  color: #fff;
  font-size: 18px;
  border-radius: 25px;
  cursor: pointer;
  transition: 0.25s ease;
}

form .btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}
