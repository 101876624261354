.retry-component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  color: #333;
}

.reload-icon {
  margin-right: 10px;
  font-size: 18px;
  color: #f44336; /* Red color for the reload icon, you can change it as needed */
}

.error-text {
  flex: 1;
}

.retry-button {
  margin-left: 10px;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.retry-button:hover {
  background-color: #f9f9f9;
  border-color: #999;
}
